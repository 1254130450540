<template>
  <div class="row">
    <div class="col-xl-12">
      <!-- AKTIONEN -->

      <container-headline
        class="col-xl-12"
        :headline="$t('global.examgrades')"
        :col="3"
      >
        <div class="row">
          <div class="ml-4 mr-2">
            <button
              class="btn btn-success"
              @click="notenSpeichern()"
              v-if="editable"
            >
              {{ $t("global.savegrades") }}
            </button>
          </div>
          <button class="btn btn-primary" @click="erstelleExcel">
            <font-awesome-icon icon="fa-duotone fa-print" class="mr-2" />
            <em>{{ $t("global.createexcel") }}</em>
          </button>
        </div>
      </container-headline>

      <!-- INHALT -->

      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="notenerfassungtable"
              tbody-tr-class="item"
              :items="notenliste.length > 0 ? notenliste : []"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              responsive
              small
              thead-tr-class="pointer"
            >
              <template #thead-top>
                <b-tr>
                  <b-th colspan="5"
                    ><span class="sr-only">{{
                      $t("global.nameandid")
                    }}</span></b-th
                  >
                  <b-th
                    class="text-center border-left"
                    colspan="2"
                    v-if="pruefungCount > 0"
                    >{{ pruefungLabel[0] }}
                  </b-th>
                  <b-th
                    class="text-center border-left"
                    colspan="2"
                    v-if="pruefungCount > 1"
                    >{{ pruefungLabel[1] }}
                  </b-th>
                  <b-th
                    class="text-center border-left"
                    colspan="2"
                    v-if="pruefungCount > 2"
                    >{{ pruefungLabel[2] }}
                  </b-th>
                  <b-th
                    class="text-center border-left"
                    colspan="2"
                    v-if="pruefungCount > 3"
                    >{{ pruefungLabel[3] }}
                  </b-th>
                  <b-th
                    class="text-center border-left"
                    colspan="2"
                    v-if="pruefungCount > 4"
                    >{{ pruefungLabel[4] }}
                  </b-th>
                </b-tr>
              </template>

              <template v-slot:cell(name)="row">
                {{ row.item.person.nachname + " " + row.item.person.vorname }}
              </template>

              <template v-slot:cell(korrektur)="row">
                <b-form-input
                  v-model="row.item.note.korrektur"
                  :on-change="checkKorrektur(row.index)"
                  :state="row.item.note.invalid"
                  :readonly="!editable"
                  :tabindex="1 + row.index"
                  @blur.native="formatKorrektur(row.index)"
                  size="sm"
                ></b-form-input>
              </template>

              <template v-slot:cell(dispensiert)="row">
                <b-button
                  class="mr-2"
                  :variant="
                    row.item.note.dispensiert == true ? 'primary' : 'light'
                  "
                  size="sm"
                  style="width: 150px"
                  :disabled="!editable"
                  :tabindex="100 + row.index"
                  @click="setDispensiert(row.index)"
                  >{{
                    row.item.note.dispensiert
                      ? $t("global.dispensed")
                      : $t("global.notdispensed")
                  }}
                </b-button>
              </template>

              <template v-if="pruefungCount > 0" v-slot:cell(gewichtung1)="row">
                {{ row.item.details[0].pruefung.gewichtung }}
              </template>
              <template v-if="pruefungCount > 1" v-slot:cell(gewichtung2)="row">
                {{ row.item.details[1].pruefung.gewichtung }}
              </template>
              <template v-if="pruefungCount > 2" v-slot:cell(gewichtung3)="row">
                {{ row.item.details[2].pruefung.gewichtung }}
              </template>
              <template v-if="pruefungCount > 3" v-slot:cell(gewichtung4)="row">
                {{ row.item.details[3].pruefung.gewichtung }}
              </template>
              <template v-if="pruefungCount > 4" v-slot:cell(gewichtung5)="row">
                {{ row.item.details[4].pruefung.gewichtung }}
              </template>

              <template v-slot:cell(pruefung1)="row">
                <b-form-input
                  v-if="row.item.details[0]"
                  v-model="row.item.details[0].bewertung.bewertung"
                  :on-change="checkBewertung(1, row.index)"
                  :state="row.item.details[0].invalid"
                  :tabindex="1000 + row.index"
                  :readonly="
                    !row.item.details[0].pruefung.editable || !editable
                  "
                  @blur.native="formatNote(1, row.index)"
                  required
                  size="sm"
                ></b-form-input>
                <b-form-invalid-feedback v-if="row.item.details[0]">
                  Die Note muss zwischen 1 und 6 liegen.
                </b-form-invalid-feedback>
              </template>
              <template v-slot:cell(pruefung2)="row">
                <b-form-input
                  v-if="row.item.details[1]"
                  v-model="row.item.details[1].bewertung.bewertung"
                  :on-change="checkBewertung(2, row.index)"
                  :state="row.item.details[1].invalid"
                  :tabindex="2000 + row.index"
                  :readonly="
                    !row.item.details[1].pruefung.editable || !editable
                  "
                  @blur.native="formatNote(2, row.index)"
                  required
                  size="sm"
                ></b-form-input>
                <b-form-invalid-feedback v-if="row.item.details[1]">
                  Die Note muss zwischen 1 und 6 liegen.
                </b-form-invalid-feedback>
              </template>
              <template v-slot:cell(pruefung3)="row">
                <b-form-input
                  v-if="row.item.details[2]"
                  v-model="row.item.details[2].bewertung.bewertung"
                  :on-change="checkBewertung(3, row.index)"
                  :state="row.item.details[2].invalid"
                  :tabindex="3000 + row.index"
                  :readonly="
                    !row.item.details[2].pruefung.editable || !editable
                  "
                  @blur.native="formatNote(3, row.index)"
                  required
                  size="sm"
                ></b-form-input>
                <b-form-invalid-feedback v-if="row.item.details[2]">
                  Die Note muss zwischen 1 und 6 liegen.
                </b-form-invalid-feedback>
              </template>
              <template v-slot:cell(pruefung4)="row">
                <b-form-input
                  v-if="row.item.details[3]"
                  v-model="row.item.details[3].bewertung.bewertung"
                  :on-change="checkBewertung(4, row.index)"
                  :state="row.item.details[3].invalid"
                  :tabindex="4000 + row.index"
                  :readonly="
                    !row.item.details[3].pruefung.editable || !editable
                  "
                  @blur.native="formatNote(4, row.index)"
                  required
                  size="sm"
                ></b-form-input>
                <b-form-invalid-feedback v-if="row.item.details[3]">
                  {{ $t("global.gradenotification") }}
                </b-form-invalid-feedback>
              </template>
              <template v-slot:cell(pruefung5)="row">
                <b-form-input
                  v-if="row.item.details[4]"
                  v-model="row.item.details[4].bewertung.bewertung"
                  :on-change="checkBewertung(5, row.index)"
                  :state="row.item.details[4].invalid"
                  :tabindex="5000 + row.index"
                  :readonly="
                    !row.item.details[4].pruefung.editable || !editable
                  "
                  @blur.native="formatNote(5, row.index)"
                  required
                  size="sm"
                ></b-form-input>
                <b-form-invalid-feedback v-if="row.item.details[4]">
                  {{ $t("global.gradenotification") }}
                </b-form-invalid-feedback>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>

              <template #custom-foot>
                <b-tr>
                  <b-th
                    v-if="pruefungCount > 0"
                    class="text-center"
                    colspan="1"
                  >
                  </b-th>
                  <b-th
                    v-if="pruefungCount > 0"
                    class="text-center"
                    colspan="1"
                  >
                    {{ calcDefClassAverage }}
                  </b-th>
                  <b-th
                    v-if="pruefungCount > 0"
                    class="text-center"
                    colspan="2"
                  >
                  </b-th>
                  <b-th
                    v-if="pruefungCount > 0"
                    class="text-center"
                    colspan="1"
                  >
                    {{ calcProvClassAverage }}
                  </b-th>
                  <b-th
                    v-if="pruefungCount > 0"
                    class="text-left border-left"
                    colspan="1"
                  >
                    {{ calcExamAverage(0) }}
                  </b-th>
                  <b-th v-if="pruefungCount > 0" class="text-right" colspan="1">
                  </b-th>
                  <b-th
                    v-if="pruefungCount > 1"
                    class="text-left border-left"
                    colspan="1"
                  >
                    {{ calcExamAverage(1) }}
                  </b-th>
                  <b-th v-if="pruefungCount > 1" class="text-right" colspan="1">
                  </b-th>
                  <b-th
                    v-if="pruefungCount > 2"
                    class="text-left border-left"
                    colspan="1"
                  >
                    {{ calcExamAverage(2) }}
                  </b-th>
                  <b-th v-if="pruefungCount > 2" class="text-right" colspan="1">
                  </b-th>
                  <b-th
                    v-if="pruefungCount > 3"
                    class="text-left border-left"
                    colspan="1"
                  >
                    {{ calcExamAverage(3) }}
                  </b-th>
                  <b-th v-if="pruefungCount > 3" class="text-right" colspan="1">
                  </b-th>
                  <b-th
                    v-if="pruefungCount > 4"
                    class="text-left border-left"
                    colspan="1"
                  >
                    {{ calcExamAverage(4) }}
                  </b-th>
                  <b-th v-if="pruefungCount > 4" class="text-right" colspan="1">
                  </b-th>
                </b-tr>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";

import ContainerHeadline from "@/components/ContainerHeadline";

import FileDownload from "js-file-download";
import App from "@/AppApi";

export default {
  components: {
    ContainerHeadline,
    BSpinner,
  },
  props: {
    noteneingabe: {
      type: String,
      required: false,
    },
    person: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      noteneingabeOverview: {
        anzahl_bewertungen: null,
        minimum: null,
        maximum: null,
        notendurchschnitt: null,
      },
      page: 0,
      anzahlProPage: 99,
      isBusy: false,
      ladeFehler: false,
      sortBy: "person.nachName",
      sortDesc: false,
      invalidFields: {},
      notenliste: [],
      pruefungCount: 0,
      pruefungLabel: [],

      fields: [],
    };
  },
  watch: {},
  computed: {
    origFields() {
      return [
        {
          key: "name",
          sortable: true,
          label: this.$t("global.participants"),
        },
        {
          key: "note.notendurchschnitt_def",
          sortable: true,
          class: "text-center",
          label: this.$t("global.semestergrade"),
        },
        {
          key: "korrektur",
          sortable: true,
          class: "text-center",
          label: this.$t("global.correction"),
        },
        {
          key: "dispensiert",
          sortable: true,
          class: "text-center",
          label: this.$t("global.dispensed"),
        },
        {
          key: "note.notendurchschnitt_prov",
          class: "text-center",
          sortable: true,
          label: this.$t("global.grades") + "-Ø-prov.",
        },
      ];
    },

    planned: function () {
      return this.$CONST("PRUEFUNGSTATI").PLANNED;
    },
    calcDefClassAverage() {
      if (this.notenliste.length === 0) return;

      let notendurchschnittDef = 0;
      let count = 0;
      this.notenliste.forEach((personDetail) => {
        if (Number(personDetail.note.notendurchschnitt_def)) {
          notendurchschnittDef += Number(
            personDetail.note.notendurchschnitt_def
          );
          count++;
        }
      });
      return this.formatNotenWert(notendurchschnittDef / count) || "-";
    },
    calcProvClassAverage() {
      if (this.notenliste.length === 0) return;

      let notendurchschnittProv = 0;
      let count = 0;
      this.notenliste.forEach((personDetail) => {
        if (Number(personDetail.note.notendurchschnitt_prov)) {
          notendurchschnittProv += Number(
            personDetail.note.notendurchschnitt_prov
          );
          count++;
        }
      });
      return this.formatNotenWert(notendurchschnittProv / count) || "-";
    },
  },
  created() {},
  mounted() {
    if (this.shown && this.notenliste.length === 0) {
      this.getNoten();
    }
    if (this.person)
      this.fields[1] = {
        key: "noteneingabe_titel",
        sortable: true,
        label: "Noteneingabe",
      };
  },
  methods: {
    calcExamAverage(examIndex) {
      if (this.notenliste.length === 0) return;
      let examDurchschnitt = 0;
      let count = 0;
      this.notenliste.forEach((personDetail) => {
        if (Number(personDetail.details[examIndex].bewertung.bewertung)) {
          examDurchschnitt += Number(
            personDetail.details[examIndex].bewertung.bewertung
          );
          count++;
        }
      });
      return this.formatNotenWert(examDurchschnitt / count) || "-";
      /*return Number(this.formatNotenWert(examDurchschnitt / count))
        ? Number(this.formatNotenWert(examDurchschnitt / count))
        : "-";*/
    },
    checkKorrektur(index) {
      if (this.notenliste.length === 0) return;

      let val = this.notenliste[index].note.korrektur;
      if (val == undefined || val == "") {
        this.invalidFields["note" + index] = null;
      } else {
        if (val < 1 || val > 6) {
          this.notenliste[index].note.invalid = false;
          this.invalidFields["note" + index] = true;
        } else {
          this.notenliste[index].note.invalid = true;
          this.invalidFields["note" + index] = null;
        }
      }

      if (val && this.notenliste[index].note.invalid) {
        this.notenliste[index].note.notendurchschnitt_def = val;
      } else {
        this.notenliste[index].note.notendurchschnitt_def =
          this.notenliste[index].note.notendurchschnitt_prov;
      }
    },

    checkBewertung(pNo, index) {
      if (this.notenliste.length === 0) return;

      if (this.notenliste[index].details[pNo - 1]) {
        let val = this.notenliste[index].details[pNo - 1].bewertung.bewertung;
        this.calcNotendurchschnittProv(this.notenliste[index]);

        if (val == undefined || val == "") {
          this.notenliste[index].details[pNo - 1].invalid = true;
          this.invalidFields["pruefung" + pNo + index] = null;
        } else {
          if (val < 1 || val > 6) {
            this.notenliste[index].details[pNo - 1].invalid = false;
            this.invalidFields["pruefung" + pNo + index] = true;
          } else {
            this.invalidFields["pruefung" + pNo + index] = null;
            this.notenliste[index].details[pNo - 1].invalid = true;
          }
        }
      }
      this.setNoteneingabeOverview();
    },

    calcNotendurchschnittProv(personDetail) {
      let bewertungSum = 0;
      let totalGewichtung = 0;

      personDetail.details.forEach((detail) => {
        if (
          Number(detail.bewertung.bewertung) &&
          Number(detail.pruefung.gewichtung)
        ) {
          bewertungSum +=
            detail.bewertung.bewertung * detail.pruefung.gewichtung;
          totalGewichtung += detail.pruefung.gewichtung;
        }
      });

      if (Number(bewertungSum / totalGewichtung)) {
        personDetail.note.notendurchschnitt_prov = this.formatNotenWert(
          bewertungSum / totalGewichtung
        );
      } else {
        personDetail.note.notendurchschnitt_prov = "-";
      }
    },

    formatNotenWert(value) {
      return value == undefined || value == "" || isNaN(value)
        ? null
        : (Math.round(value * 10) / 10).toFixed(1);
    },

    formatKorrektur(index) {
      if (this.notenliste.length === 0) return;

      this.notenliste[index].note.korrektur = this.formatNotenWert(
        this.notenliste[index].note.korrektur
      );
    },

    formatNote(pNo, index) {
      if (this.notenliste.length === 0) return;

      this.notenliste[index].details[pNo - 1].bewertung.bewertung =
        this.formatNotenWert(
          this.notenliste[index].details[pNo - 1].bewertung.bewertung
        );
    },

    validateForm() {
      let validState = true;

      Object.values(this.invalidFields).forEach((val) => {
        if (val == true) validState = false;
      });

      return validState;
    },
    updateTable() {
      if (this.notenliste.length === 0) return;

      let count = 1;
      this.origFields.splice(5);
      this.fields = this.origFields;
      this.pruefungLabel = [];

      this.notenliste[0].details.forEach((item) => {
        this.fields.push({
          key: "pruefung" + count,
          sortable: false,
          class: "text-center border-left",
          label: "Note",
        });

        this.fields.push({
          key: "gewichtung" + count,
          sortable: false,
          class: "text-center",
          label: "Gew.",
        });

        if (item.pruefung && item.pruefung.bezeichnung)
          this.pruefungLabel.push(item.pruefung.bezeichnung);

        count++;
      });

      this.pruefungCount = count - 1;
      this.isBusy = false;
    },

    async getNoten() {
      this.isBusy = true;

      App.get("noteneingabe/pruefungsnote/", {
        params: {
          id: this.noteneingabe,
        },
      })
        .then((response) => {
          this.notenliste = response.data;
          let scope = this;
          if (this.notenliste[0]) {
            let plannedId = this.planned;
            if (this.notenliste[0].details) {
              this.notenliste.forEach(function (benotung) {
                benotung.note.korrektur = scope.formatNotenWert(
                  benotung.note.korrektur
                );

                benotung.note.notendurchschnitt_def = scope.formatNotenWert(
                  benotung.note.notendurchschnitt_def
                );

                benotung.note.notendurchschnitt_prov = scope.formatNotenWert(
                  benotung.note.notendurchschnitt_prov
                );

                benotung.details.forEach(function (detail) {
                  if (!detail.pruefung.status) {
                    detail.pruefung.status = {
                      id: plannedId,
                    };
                  }

                  //detail.pruefung.status.id == plannedId ? true : false;
                  detail.pruefung.editable =
                    detail.pruefung.status.id === plannedId;

                  detail.bewertung.bewertung = scope.formatNotenWert(
                    detail.bewertung.bewertung
                  );
                });
              });

              this.updateTable();
            }
          }
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    setDispensiert(index) {
      if (this.notenliste.length === 0) return;

      this.notenliste[index].note.dispensiert =
        !this.notenliste[index].note.dispensiert;
    },

    setNoteneingabeOverview() {
      if (this.notenliste.length === 0) return;

      let notenCount = 0;
      let noteMax = 0;
      let noteMinimum = Infinity;
      this.notenliste.forEach((personDetail) => {
        personDetail.details.forEach((detail) => {
          if (Number(detail.bewertung.bewertung)) {
            notenCount++;
            if (detail.bewertung.bewertung > noteMax)
              noteMax = detail.bewertung.bewertung;
            if (detail.bewertung.bewertung < noteMinimum)
              noteMinimum = detail.bewertung.bewertung;
          }
        });
      });

      this.noteneingabeOverview.maximum = noteMax;
      this.noteneingabeOverview.minimum =
        noteMinimum === Infinity ? 0 : noteMinimum;
      this.noteneingabeOverview.anzahl_bewertungen = notenCount;
      this.noteneingabeOverview.notendurchschnitt = Number(
        this.calcDefClassAverage
      )
        ? Number(this.calcDefClassAverage)
        : 0;

      this.$emit("overviewChanged", this.noteneingabeOverview);
    },

    async notenSpeichern(switched) {
      if (!this.validateForm()) return;

      this.isBusy = true;

      let json = Object.assign(this.notenliste);

      if (json.length > 0) {
        json.forEach((personDetail) => {
          if (personDetail.note.notendurchschnitt_def === "-")
            personDetail.note.notendurchschnitt_def = 0;
          if (personDetail.note.notendurchschnitt_prov === "-")
            personDetail.note.notendurchschnitt_prov = 0;

          personDetail.details.forEach((detail) => {
            if (!isNaN(detail.bewertung.bewertung)) {
              let bewertung = Number(detail.bewertung.bewertung);

              if (bewertung >= 1.0) {
                detail.bewertung.bewertung = bewertung;
              } else {
                detail.bewertung.bewertung = 0;
              }
            }
          });
        });

        App.put("noteneingabe/pruefungsnote/", json, {
          params: { id: this.noteneingabe },
        })
          .then((response) => {
            this.notenliste = response.data;
            let scope = this;
            if (this.notenliste[0]) {
              let plannedId = this.planned;
              if (this.notenliste[0].details) {
                this.notenliste.forEach(function (benotung) {
                  benotung.note.korrektur = scope.formatNotenWert(
                    benotung.note.korrektur
                  );

                  benotung.note.notendurchschnitt_def = scope.formatNotenWert(
                    benotung.note.notendurchschnitt_def
                  );

                  benotung.note.notendurchschnitt_prov = scope.formatNotenWert(
                    benotung.note.notendurchschnitt_prov
                  );

                  benotung.details.forEach(function (detail) {
                    if (!detail.pruefung.status) {
                      detail.pruefung.status = {
                        id: plannedId,
                      };
                    }

                    //detail.pruefung.status.id == plannedId ? true : false;
                    detail.pruefung.editable =
                      detail.pruefung.status.id === plannedId;

                    detail.bewertung.bewertung = scope.formatNotenWert(
                      detail.bewertung.bewertung
                    );
                  });
                });

                this.updateTable();
              }
            }
            this.ladeFehler = false;

            if (!switched) {
              this.$notify({
                type: "success",
                title: this.$t("notification.actionsuccessful"),
                text: this.$t("notification.gradessavedsuccessfully"),
              });
            }
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.isBusy = false;
            if (switched) {
              this.$router.push({
                name: "noteneingabe",
                params: { id: this.noteneingabe, anzeige: 0 },
              });
            }
          });
      } else {
        if (switched) {
          this.$router.push({
            name: "noteneingabe",
            params: { id: this.noteneingabe, anzeige: 0 },
          });
        }
      }
    },
    erstelleExcel() {
      let pruefungen = this.notenliste[0].details.map((el) => el.pruefung);

      Api.post(
        "/noten/notenerfassung/notenexport/",
        {
          noteneingabe: this.noteneingabe,
          pruefungen: pruefungen,
          notenliste: this.notenliste,
        },
        { responseType: "blob" } //SWE: notwendig damit OutputExcel nicht beschädigt wird
      )
        .then((response) => {
          this.excelFile = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileDownload(
            this.excelFile,
            `Noteneingabe-${
              this.noteneingabe
            }-${new Date().toLocaleDateString()}.xlsx`
          );
          this.loading = false;
        })
        .catch(() => {
          setTimeout(() => {
            this.$notify({
              type: "error",
              title: this.$t("notification.actionfailed"),
              text: this.$t("notification.errorwhilecreatingtheexcelfile"),
            });
          }, 2000);
          this.loading = false;
        });
    },
  },
};
</script>
